.tabs {
  display: flex;
  margin-bottom: 22px;
}

.tab {
  margin-right: 15px;

  .lable {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #9aa1b0;

    &__active {
      cursor: default;
      color: #1e1e20;
    }
  }

  .border {
    margin-top: 4px;
    width: 100%;
    height: 2px;
    background: #1e1e20;
  }
}
