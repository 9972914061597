.block {
  width: 304px;
  height: 36px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 100px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  //justify-content: space-between;
  cursor: text;

  .input {
    color: #1e1e20;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    border: none;
    outline: none;
    margin-left: 8px;

    &::placeholder {
      color: #9aa1b0;
    }
  }
}
