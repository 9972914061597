.block {
  overflow-y: scroll;
}

.item {
  border-bottom: 1px solid #f0f2f5;
  height: 88px;
  padding: 12px 16px 12px 32px;
  cursor: pointer;

  &__active {
    background: #f0f2f5;
  }

  .row {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9aa1b0;
    justify-content: space-between;
  }

  .rowSecond {
    display: flex;
    margin-top: 8px;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #d9d9d9;
      background: white;
      margin-right: 8px;
      overflow: hidden;

      &__active {
        border: none;
      }

      .img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      width: 270px;
    }

    .name {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #1e1e20;
      margin-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .message {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #9aa1b0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .newMessages {
      margin-left: 11px;
      width: 22px;
      height: 22px;
      background: #eb5d18;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #f9f9fa;
    }
  }
}
