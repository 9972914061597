.block {
  width: 446px;
  max-height: 142px;
  position: fixed;
  bottom: 32px;
  right: 32px;
  background: rgba(89, 100, 122, 0.9);
  border-radius: 1px;
  z-index: 99;

  .container {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 24px 24px 16px 16px;
    display: flex;

    .button {
      position: absolute;
      top: 10px;
      right: 8px;
    }

    .avatarBlock {
      width: 40px;
      height: 40px;
      margin-right: 12px;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: white;
      }
    }

    .name {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 6px;
    }

    .text {
      max-height: 72px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .time {
      position: absolute;
      bottom: 8px;
      right: 8px;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #d9d9d9;
    }
  }
}
