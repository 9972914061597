.block {
  display: flex;
  margin-bottom: 32px;

  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 16px;
    border: 1px solid #d9d9d9;
    overflow: hidden;

    &__active {
      border: none;
    }
  }

  .img {
    width: 100%;
    height: 100%;
  }

  .mentorName {
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    color: #1e1e20;
    margin-bottom: 4px;
  }

  .courseName {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #59647a;
  }
}
