@import '../src/shared/theme/colors.scss';
@import '../src/shared/theme/typography.scss';

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  scrollbar-gutter: stable;
}

ul,
ol,
p {
  padding: 0;
  margin: 0;
}

a:active,
a:hover,
a {
  text-decoration: none;
  color: $clr-black;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}