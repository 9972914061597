.block {
  height: 110px;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  position: relative;

  .button {
    display: none;
  }

  &__modile {
    position: fixed;
    top: 0;
    z-index: 100;
    background: white;
    height: 64px;

    .button {
      display: block;
      left: 16px;
      top: 24px;
      position: absolute;
    }

    .information {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .avatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      .block {
        height: 64px;
        width: fit-content;

        .detailedInformation {
          .name {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #1e1e20;
            margin-bottom: 0;
          }

          .detailedInformationBlottom {
            display: none;
          }
        }
      }
    }
  }
}

.information {
  padding: 0 24px;
  display: flex;
  align-items: center;

  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 16px;
    border: 1px solid #d9d9d9;
    overflow: hidden;

    &__active {
      border: none;
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .block {
    display: flex;
    align-items: center;

    .detailedInformation {
      margin-right: 16px;

      .name {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #1e1e20;
        margin-bottom: 4px;
      }

      .detailedInformationBlottom {
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #59647a;

        .point {
          margin: 0 8px;
        }
      }
    }
  }
}
