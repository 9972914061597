.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;

  .block {
    position: relative;
    padding: 40px;
    background: #ffffff;

    .button {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
    }
  }
}

/* .block {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
