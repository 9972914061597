@use '../../constants/variables.scss' as *;
@use '../../theme/colors.scss' as *;

.userBlock {
  display: flex;
  align-items: center;
  cursor: pointer;

  .userName {
    margin: 0 4px 0 8px;
  }
}

.user {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: $page-container-side-margin;

  .avatar {
    width: 32px;
    clip-path: circle(15px);
  }

  &-menu {
    cursor: pointer;

    &-opened {
      transform: rotate(180deg);
    }
  }

  &-profile-menu {
    position: absolute;
    top: 64px;
    right: $page-container-side-margin;

    &-progress-wrapper {
      display: flex;
      justify-content: center;
    }

    &-mobile {
      right: 16px;
    }
  }

  &-mobile {
    margin-right: 16px;

    .avatar {
      width: 40px;
      height: 40px;
      clip-path: circle(20px);
    }
  }
}

.page-header {
  justify-content: flex-end;
}

.aside {
  width: $aside-width;
  height: 100vh;
  background-color: $clr-grey2;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;

  &:focus {
    outline: none;
  }

  &-tablet {
    width: 475px;
  }

  &-mobile {
    width: 100%;
  }

  & > .buttons {
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.close {
  cursor: pointer;

  & > path {
    fill: black;
  }
}

.aside-menu-item {
  padding: 11px 7px 11px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  cursor: pointer;

  &-chat {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.courses-item {
  padding: 4px 16px 4px 43px;
  cursor: pointer;
  width: fit-content;
  position: relative;

  &::before {
    position: absolute;
    left: 32px;
    content: '\2022';
  }
}

.logo {
  cursor: pointer;
}

.buttonBack {
  position: absolute;
  left: 240px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #59647a;
  display: flex;
  align-items: center;
  margin: 22px 0 0 48px;
  cursor: pointer;

  & > svg {
    margin-right: 8px;
  }
}
