.block {
  padding: 39px 0 19px 48px;
  border-bottom: 1px solid #d9d9d9;

  .header {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    color: #1e1e20;
  }
}
