@import 'shared/theme/colors.scss';

.wrapper {
  max-width: 728px;
  min-height: 320px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 16px 4px 32px rgba(101, 104, 118, 0.3);
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.lesson-info {
  color: $clr-grey4;
}

.button {
  margin-top: auto;
  width: 243px;
}

.background {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
}
