.deleteButton {
  display: block;
  width: 36px;
  height: 36px;
  background: #59647a;
  border-radius: 0 8px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 0;
}
