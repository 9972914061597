.delete {
  background: #59647a;
  border-radius: 0px 8px 0px 0px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -1px;
}
