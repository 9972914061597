@use 'shared/constants/variables.scss' as *;
@use 'shared/theme/colors.scss' as *;
@use 'shared/theme/mixins.scss' as *;

.page-container {
  @include page-container;

  &-tablet {
    @include page-container-tablet;
  }

  &-mobile {
    @include page-container-mobile;
  }
}

.course-header {
  margin-top: 4px;
}

.current-lesson {
  margin-top: 24px;
  width: 100%;

  &-text {
    max-width: 516px;
  }

  &-mobile {
    padding: 24px 16px;
    min-height: 304px;
  }
}

.progress {
  width: 100%;

  &-container {
    display: flex;
    margin-top: 12px;
    gap: 8px;
  }

  &-description {
    margin-top: 8px;
    color: $clr-grey4;
  }
}

.header {
  margin-top: 48px;
}

.modules-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
}

.moduleCard {
  width: 352px;
  height: 258px;
  padding: 24px;
  border-radius: 8px;

  &-mobile {
    padding: 16px;
    height: 156px;
    width: 100%;
  }
}
