.block {
  width: 400px;
  border-right: 1px solid #d9d9d9;

  &__mobile {
    width: 100%;
    border-right: none;

    &__isNotVisible {
      display: none;
    }
  }
}
